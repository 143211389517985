<template>
  <div class="wrapper">
    <div class="container-full flex-col">
      <jumbotron class="w-full"></jumbotron>
      <process-widget></process-widget>
      <how-does-it-look></how-does-it-look>
      <features></features>
      <achievements></achievements>
      <process class="w-full"></process>
      <partner-feedback></partner-feedback>
      <pricing></pricing>
      <dont-wait></dont-wait>
      <contact></contact>
    </div>
  </div>
</template>

<script>
import Jumbotron from "@/components/public/home/HomeHeader";
import ProcessWidget from "@/components/public/landing/ProcessWidget";
import Achievements from "@/components/public/home/Achievements";
import Process from "@/components/public/Process";
import HowDoesItLook from "@/components/public/HowDoesItLook";
import Features from "@/components/public/product/Features";
import PartnerFeedback from "@/components/public/PartnerFeedback";
import Pricing from "@/components/public/Pricing";
import DontWait from "@/components/public/DontWait";
import Contact from "@/components/public/Contact";

export default {
  components: {
    Jumbotron,
    ProcessWidget,
    Process,
    Achievements,
    HowDoesItLook,
    PartnerFeedback,
    Pricing,
    DontWait,
    Contact,
    Features
  }
};
</script>
