<template>
  <div class="w-full bg-offwhite flex px-16 py-16 mobile:px-4">
    <div
      class="flex mobile:flex-col w-3/4 mobile:w-full mx-auto px-4 mobile:px-0"
    >
      <div class="w-1/2 mobile:w-full flex flex-col">
        <span
          class="text-5xl mobile:text-4xl font-extrabold mobile:text-center mobile:mb-8 pr-6 mobile:pr-0"
          >Säästa üle <span class="text-green">50% </span>töö korraldamisele
          kuluvast ajast.
          <span
            class="text-green text-5xl font-extrabold mobile:text-center mobile:mb-8"
            >Soodsalt.</span
          >
        </span>

        <span class="text-xl mt-4 mb-8"
          ><span class="font-medium"
            >Paljud väiksed ülesanded nagu tööülesannete planeerimine ja
            pakkumiste koostamine söövad varjatult su tööaega.</span
          >
          Võta appi Gewodo ärihaldus ja kuluta oma aega sellele, mis päriselt
          raha sisse toob.</span
        >
        <div
          class="flex mb-2 mobile:flex-col medium:flex-col mobile:items-center"
        >
          <router-link
            :to="$store.state.auth.status.loggedIn ? '/calendar' : '/register'"
            class="mr-3 mobile:mr-0 mobile:mb-4 mobile:w-full"
          >
            <button
              class="bg-turquoise hover:bg-turquoisedark hover:shadow-turquoiseoutline border-2 border-turquoise text-white font-semibold text-xl py-2 px-4 mobile:w-full"
            >
              Proovi TASUTA
            </button></router-link
          >
          <router-link to="/product" class="mobile:w-full">
            <button
              class="bg-transparent border-2 py-2 px-4 text-xl font-semibold hover:bg-white hover:border-green hover:text-green hover:shadow-outline mobile:w-full"
            >
              Tutvu tootega
            </button>
          </router-link>
        </div>

        <span class="mb-2 text-xs mobile:mx-2"
          >* proovi 14 päeva tasuta Premium paketti. Baaspakett ALATI tasuta.
          Vaata hinnastamist
          <router-link to="/pricing" class="font-medium hover:text-green"
            >SIIT</router-link
          >.</span
        >
      </div>
      <div class="w-1/2 flex justify-center items-center mobile:hidden">
        <img src="/header/about_us.png" class="rounded-xl" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: ""
    };
  }
};
</script>
