<template>
  <div class="achievements-container">
    <h1 class="mb-16 mobile:mb-8">Tunnustatud lahendus</h1>
    <div class="achievements-row">
      <div class="achievement-container">
        <a href="https://www.startupincubator.ee/" target="_blank">
          <img src="/achievements/incubator.png" class="achievement-image" />
        </a>
        <span class="achievement-divider"></span>
        <span class="achievement-text"
          >Tehnopoli Startup Inkubaatori liige 2021</span
        >
      </div>
      <div class="achievement-container">
        <a href="https://prototron.ee/" target="_blank">
          <img src="/achievements/prototron.png" class="achievement-image" />
        </a>
        <span class="achievement-divider"></span>
        <span class="achievement-text">Prototroni võistluse TOP 30</span>
      </div>
      <div class="achievement-container">
        <a href="https://www.superangel.io/basecamp" target="_blank">
          <img src="/achievements/basecamp.png" class="achievement-image" />
        </a>
        <span class="achievement-divider"></span>
        <span class="achievement-text"
          >Garage 48 & Superangel Basecamp Spring häkatoni 30 valitu seas</span
        >
      </div>
      <div class="achievement-container">
        <a href="https://techchill.co/" target="_blank">
          <img src="/achievements/techchill.png" class="achievement-image"
        /></a>
        <span class="achievement-divider"></span>
        <span class="achievement-text"
          >Techchill 50 startups battle poolfinaal</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.achievements-container {
  @apply flex flex-col items-center py-16 w-9/12 mx-auto;
  .achievements-row {
    @apply flex justify-between w-full px-16;
    .achievement-container {
      @apply flex flex-col w-1/4 items-center;
      a {
        @apply h-48 flex items-center w-40 justify-center rounded-xl;
        .achievement-image {
          @apply rounded-xl;
          object-fit: contain;
        }
      }
      .achievement-divider {
        @apply h-1 bg-green my-4 w-1/5 rounded-xl;
      }
      .achievement-text {
        @apply text-center w-9/12;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .achievements-container {
    .achievements-row {
      @apply flex-col px-0;
      .achievement-container {
        @apply w-full mb-8;
        .achievement-text {
          @apply w-full;
        }
      }
    }
  }
}
</style>
