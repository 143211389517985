<template>
  <div
    class="flex flex-col items-center py-16 w-9/12 mx-auto mobile:px-2 mobile:w-full mobile:text-center"
  >
    <span class="text-5xl font-semibold mb-4">Kõik, mida vajad.</span>
    <span class="font-semibold mb-12"
      >Ühes kohas. <span class="text-green">Lihtsalt</span>.
      <span class="text-green">Soodsalt</span>.</span
    >
    <div class="flex flex-wrap px-16 justify-center mobile:px-0">
      <div
        class="w-1/4 mobile:w-full flex flex-col pr-12 mobile:pr-0 items-center mb-8"
      >
        <img src="/features/proman.svg" class="h-20 mb-2" />
        <span class="font-semibold">Projektihaldus</span>
        <span class="w-1/6 rounded-full bg-green feature-underline"></span>
        <span class="w-3/4"
          >Ülesanded, dokumendid, vestlused, kõik igal projektil</span
        >
      </div>
      <div
        class="w-1/4 mobile:w-full flex flex-col px-6 mobile:px-0 items-center mb-8"
      >
        <img src="/features/docman.svg" class="h-20 mb-2" />
        <span class="font-semibold">Dokumendihaldus</span>
        <span class="w-1/6 rounded-full bg-green feature-underline"></span>
        <span class="w-3/4">Kõik ettevõtte dokumendid ühes kohas koos.</span>
      </div>
      <div
        class="w-1/4 mobile:w-full flex flex-col px-6 mobile:px-0 items-center text-center mb-8"
      >
        <img src="/features/climan.svg" class="h-20 mb-2" />
        <span class="font-semibold">Kliendihaldus</span>
        <span class="w-1/6 rounded-full bg-green feature-underline"></span>
        <span class="w-3/4"
          >Hoia silm peal kõikidel oma klientidel ja nende infol.</span
        >
      </div>
      <div
        class="w-1/4 mobile:w-full flex flex-col pl-12 mobile:px-0 items-center text-center mb-8"
      >
        <img src="/features/empman.svg" class="h-20 mb-2" />
        <span class="font-semibold">Töötajate haldus</span>
        <span class="w-1/6 rounded-full bg-green feature-underline"></span>
        <span class="w-3/4"
          >Lisa, kustuta ja muuda töötajaid ja nende infot.</span
        >
      </div>
      <div
        class="w-1/4 mobile:w-full flex flex-col pr-12 mobile:px-0 items-center text-center mb-8"
      >
        <img src="/features/clipor.svg" class="h-20 mb-2" />
        <span class="font-semibold">Kliendiportaal</span>
        <span class="w-1/6 rounded-full bg-green feature-underline"></span>
        <span class="w-3/4"
          >Sinu klient näeb kõiki oma projekte, pakkumisi ja arveid.</span
        >
      </div>
      <div
        class="w-1/4 mobile:w-full flex flex-col px-6 mobile:px-0 items-center text-center mb-8"
      >
        <img src="/features/tt.svg" class="h-20 mb-2" />
        <span class="font-semibold">Ajamõõtmine</span>
        <span class="w-1/6 rounded-full bg-green feature-underline"></span>
        <span class="w-3/4"
          >Iga töötaja saab mõõta oma tööaega ülesandepõhiselt.</span
        >
      </div>
      <div
        class="w-1/4 mobile:w-full flex flex-col px-6 mobile:px-0 items-center text-center mb-8"
      >
        <img src="/features/pay.svg" class="h-20 mb-2" />
        <span class="font-semibold">Palgaarvestus</span>
        <span class="w-1/6 rounded-full bg-green feature-underline"></span>
        <span class="w-3/4"
          >Saad jälgida iga töötaja töötunde ja töötasu.
        </span>
      </div>
      <div
        class="w-1/4 mobile:w-full flex flex-col pl-6 mobile:px-0 items-center text-center mb-8"
      >
        <img src="/features/off.svg" class="h-20 mb-2" />
        <span class="font-semibold">Pakkumised</span>
        <span class="w-1/6 rounded-full bg-green feature-underline"></span>
        <span class="w-3/4"
          >Loo pakkumine Gewodos ja saada see kliendile välja.</span
        >
      </div>
      <div
        class="w-1/4 mobile:w-full flex flex-col px-6 mobile:px-0 items-center text-center"
      >
        <img src="/features/inv.svg" class="h-20 mb-2" />
        <span class="font-semibold">Arved</span>
        <span class="w-1/6 rounded-full bg-green feature-underline"></span>
        <span class="w-3/4">Koosta arve saada see otse Gewodost välja.</span>
      </div>
      <div
        class="w-1/4 mobile:w-full flex flex-col px-6 mobile:px-0 items-center text-center"
      >
        <img src="/features/comm.svg" class="h-20 mb-2" />
        <span class="font-semibold">Suhtlus</span>
        <span class="w-1/6 rounded-full bg-green feature-underline"></span>
        <span class="w-3/4"
          >Saada projekti vestluses seotud infot, pilte ja faile</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.feature-underline {
  @apply my-2;
  height: 2px;
}
</style>
